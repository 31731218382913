* {
  box-sizing: border-box;
}

body {
  text-align: center;
  /* background-color: #eeeeee; */
  background-color: #E8EAED;
}

.Row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}
.Row-letter-color {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.Row-annotation {
  margin-inline-start: 16px;
  width: 5em;
  text-align: start;
}

.App-container {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.App-container h1 {
  margin-top: 0;
}

.Game,
h1 {
  user-select: none;
}

.Game {
  display: flex;
  flex-direction: column;
}

table.Game-rows {
  margin: auto;
  display: flex;
  flex-direction: column;
}

table.Game-rows:focus {
  outline: none;
}

table.Game-rows > tbody {
  display: flex;
  flex-direction: column;
}

.Game-keyboard {
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: white;
  opacity: .88;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 54px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2;
}

.Game-keyboard-button:focus {
  outline: none;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  /* background-color: rgb(87, 172, 120); */
  background-color:  #43A047;
  color: white !important;
}

.letter-elsewhere {
  border: 2px dotted rgba(0, 0, 0, 0.3);
  /* background-color: #e9c601; */
  background-color: #FBC02D;
  color: white !important;
}

.letter-absent {
  border: 2px solid transparent;
  /* background-color: rgb(162, 162, 162); */
  background-color: #80868B;
  color: white !important;
}

body.dark {
  background-color: #0E1013;
  color: white;
  opacity: .88;
  color-scheme: dark;
}

body.dark .Game-keyboard-button {
  color: #404040;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Game-options > * + * {
  margin-inline-start: 0.5rem;
}

.Game-options button {
  min-width: 4rem;
}

.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.App-about {
  margin-top: -1rem;
  line-height: 1.4;
}

.App-about b {
  /* background-color: #888; */
  background-color: #80868B;
  color: white;
  padding: 1px 3px;
  border-radius: 2px;
}

.App-about b.green-bg {
  /* background-color: rgb(87, 172, 120); */
  background-color: #43A047;
}

.App-about b.yellow-bg {
  /* background-color: #e9c601; */
  background-color: #FBC02D;
}

.Game-seed-info {
  margin: 20px 0px 0px 0px;

  opacity: 0.88;
  font-variant-numeric: tabular-nums;
  justify-content: center;
  display: flex;
  align-items: center;
}
.Game-seed{
  width: 40%;
  justify-content: flex-start;
}
.Game-seed-text{
  width: 60%;
  justify-content: flex-end;
  text-align: end;
}
.switchSelectorStyle{
  font-weight: 500;
  color: #FFFFFF;
}
.Game-sr-feedback,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Settings {
  text-align: left;
  font-size: 18px;
}

.Settings-setting {
  margin: 8px;
  display: flex;
  align-items: top;
}

.Settings-setting input {
  width: 18px;
  height: 18px;
}

.Settings-setting input[type="range"] {
  width: 50px;
  height: 18px;
}

.Settings-setting label {
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}

.today-meta-setting {
  text-align: left;
  font-size: 18px;
}

/* .top-right {
  position: absolute;
  top: 10px;
  right: 3px;
} */
.top-right, .settings {
  line-height: 0;
  width: 30px;
}
.emoji-link {
  font-size: 24px;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  line-height: 0;
}

.top-right > * + * {
  margin-inline-start: 9px;
}

.App-container.color-blind .letter-correct,
.App-container.color-blind .App-about b.green-bg {
  /* background-color: #f5793a; */
  background-color: #F57C00;
}

.App-container.color-blind .letter-elsewhere,
.App-container.color-blind .App-about b.yellow-bg {
  /* background-color: #85c0f9; 
  background-color: #66B5FF; */
  background-color: #03A9F4;
}

.row {
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
}
/* .buttons-container{
  width: 100%; */
  /* border: 1px solid black; */
  /* display: flex;
  justify-content: space-between;
} */

/* .column-merch {
  flex-basis: 24%;
} */

/* .merch {
  font-size: 18px;
  background-color: #66B5FF;
  padding: 5px 15px 5px 15px;
  width: 100%;
} */

.column-social {
  background: transparent;
  margin: 0;
  flex-basis: 20%;
  padding: 5px;
}

.share-link {
  font-size: 16px;
}

.svg-icon1 {
  filter: brightness(0) saturate(100%) invert(49%) sepia(25%) saturate(7494%) hue-rotate(188deg) brightness(104%) contrast(91%);
  height: 24px;
}

.svg-icon2 {
  /* filter: invert(65%) sepia(14%) saturate(3161%) hue-rotate(185deg) brightness(103%) contrast(105%); */
  /* filter: brightness(0) saturate(100%) invert(51%) sepia(53%) saturate(1161%) hue-rotate(184deg) brightness(104%) contrast(92%); */
  filter: brightness(0) saturate(100%) invert(49%) sepia(25%) saturate(7494%) hue-rotate(188deg) brightness(104%) contrast(91%);
  height: 28px;
}

a,
a:visited {
  color: #F50057;
}

a:active {
  color: green;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: green;
}

img:hover {
  transition: all .2s ease-in-out;
  transform: scale(1.3);
}

.copyright {
 font-size: 14px;
}




.buttons-container {
  width: 100%;
   /* border: 1px solid black;  */
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.column-merch {
  flex-basis: 24%;
  background: #66B5FF;
  width: 24%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.merch {
  font-size: 16px;
  background-color: transparent;
  padding: 5px 15px 5px 15px;
  width: 100%;
  border: 0;
  word-break: break-all;
}
.disflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.disflex tr.Row.Row-locked-in {
  width: calc(100% - 70px);
}
/* Use a media query to add a breakpoint at 420px: */
@media screen and (max-width: 420px) {
  .column-merch {
    flex: 20%;
    margin: 3px;
  }
  .merch {
    font-size: 14px;
    padding: 3px;
    }
    .Game-seed-text {
      font-size: 14px;
    }
    .switchSelectorStyle {
      font-size: 14px;
  }
  .your-required-wrapper {
    width: 100px !important;
}
  }
  
  /* Use a media query to add a breakpoint at 380px: */
  @media screen and (max-width: 380px) {
  .column-merch {
    flex: 20%;
    margin: 1px;
  }
  }